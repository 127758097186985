import { ParsedUrlQuery } from 'querystring';
import * as Separator from '@radix-ui/react-separator';
import { queryTypes, useQueryState } from 'next-usequerystate';
import { useRouter } from 'next/router';
import { ReactElement } from 'react';
import { BackButton } from '@/components';
import {
  ReportsByOrganizationFilter,
  useGetReportQuery,
} from '@/generated/graphql';
import { useMe } from '@/hooks';
import { getDisplayStringForChainType } from '@/types/chain';
import { PageProps, PageType } from '@/types/page';
import {
  BROWSE_ALL_ROUTE,
  getRouteForAddressSearch,
  getRouteForCategory,
  getRouteForChain,
  getRouteForDomainSearch,
  getRouteForOrganizationMyFeed,
  getRouteForProfile,
  QueryParamNames,
} from '@/types/routes';
import { getDisplayStringForScamCategory } from '@/types/scam-categories';
import {
  getPagePropsFromQueryParams,
  getScamReportCardPropsFromReport,
  makeElementClassNameFactory,
  makeRootClassName,
} from '@/utils';
import { PRIVATE_REPORT_DUMMY_DETAILS } from '@/dummy/results';
import {
  ErrorPageBody,
  ScamReportCardProps,
  ScamReportDetails,
  ScamReportDetailsLoading,
} from '..';
import SuccessPageContainer from '../submit-report-page/components/success-page/SuccessPage';
import { Layout, ReportKeywords } from './components';
import { FileReportButton } from './components/file-report-button';

type SingleReportPageProps = {
  /**
   * The page to make it look like the single report is rendered on
   * (usually the page that routed here and sourced through the query params)
   */
  context: PageType;
  /** The id of the report data */
  reportId: string;
  /** The report details */
  report?: ScamReportCardProps;
  /** Whether the report fetching is loading  */
  isLoading?: boolean;
  /** Message if there was an error */
  error?: string;
  /** Whether to show the keywords sidebar, instead of the comment sidebar */
  showKeywords?: boolean;
  /** If keywords should be shown, the keywords to highlight */
  keywords?: string[];
  /** Handler when the user chooses back */
  onBack: () => void;
};

const ROOT = makeRootClassName('ResultsSection');
const el = makeElementClassNameFactory(ROOT);

const DEFAULT_PROPS = {} as const;

const getPreviousRouteFromContextParam = (
  query: ParsedUrlQuery,
  context: PageType
): string => {
  const previousPageProps = getPagePropsFromQueryParams({
    query,
    context,
  }) as PageProps; // @TODO this isn't great..

  switch (previousPageProps.pageType) {
    case PageType.BROWSE_CHAIN: {
      const { chain } = previousPageProps;
      return getRouteForChain(chain);
    }
    case PageType.BROWSE_CATEGORY: {
      const { category } = previousPageProps;
      return getRouteForCategory(category);
    }
    case PageType.SEARCH_DOMAIN: {
      const { domain } = previousPageProps;
      return getRouteForDomainSearch(domain);
    }
    case PageType.SEARCH_ADDRESS: {
      const { address, chain } = previousPageProps;
      return getRouteForAddressSearch(address, chain);
    }
    case PageType.PROFILE:
    case PageType.PROFILE_OTHER:
    case PageType.PROFILE_SELF: {
      const { username } = previousPageProps;
      return getRouteForProfile(username);
    }
    case PageType.MY_FEED: {
      const { orgId } = previousPageProps;
      return getRouteForOrganizationMyFeed(orgId);
    }
    case PageType.SUBDOMAIN_FEED:
      return '/'; // return to base route, which will be subdomain page like solana.chainabuse.com
    default:
      return BROWSE_ALL_ROUTE;
  }
};

const getPreviousContextBackButtonCopy = (
  query: ParsedUrlQuery,
  context: PageType,
  reportId: string
): string => {
  const previousPageProps = getPagePropsFromQueryParams({
    query,
    context,
    reportId,
  }) as PageProps; // @TODO this isn't great..

  switch (previousPageProps.pageType) {
    case PageType.BROWSE_CHAIN: {
      const { chain } = previousPageProps;
      return `${getDisplayStringForChainType(chain)} Reports`;
    }
    case PageType.BROWSE_CATEGORY: {
      const { category } = previousPageProps;
      return `${getDisplayStringForScamCategory(category)} Reports`;
    }
    case PageType.SEARCH_DOMAIN:
    case PageType.SEARCH_ADDRESS:
      return 'search results';
    case PageType.PROFILE:
    case PageType.PROFILE_OTHER: {
      const { username } = previousPageProps;
      return `${username} reports`;
    }
    case PageType.PROFILE_SELF: {
      return 'your reports';
    }
    default:
      return 'results';
  }
};

function SingleReportPage(props: SingleReportPageProps): ReactElement {
  const p = { ...DEFAULT_PROPS, ...props };
  const { query } = useRouter();

  const shouldShowKeywords = p.showKeywords && p.keywords;
  const isSuccessfulSubmission = !!query.submitted;

  return (
    <Layout.Root>
      <Layout.Main>
        {isSuccessfulSubmission ? (
          <SuccessPageContainer reportId={p.reportId} />
        ) : (
          <>
            <Layout.ResultsHeader>
              <BackButton onPress={props.onBack}>
                {`Back to all
                    ${getPreviousContextBackButtonCopy(
                      query,
                      p.context,
                      p.report?.reportId ?? ''
                    )}
                  `}
              </BackButton>
            </Layout.ResultsHeader>
            <Layout.Results>
              {p.report && (
                <ScamReportDetails
                  {...p.report}
                  {...(p.report.isPrivate ? PRIVATE_REPORT_DUMMY_DETAILS : {})}
                />
              )}
              {!p.report && p.isLoading && <ScamReportDetailsLoading />}
            </Layout.Results>
          </>
        )}
      </Layout.Main>
      <Layout.Sidebar>
        <Layout.SidebarFilters>
          {shouldShowKeywords && <ReportKeywords keywords={p.keywords ?? []} />}
          <Separator.Root decorative className={el`separator`} />
          <FileReportButton />
        </Layout.SidebarFilters>
      </Layout.Sidebar>
    </Layout.Root>
  );
}

export type SingleReportPageContainerProps = {
  pageType: PageType.MY_FEED | PageType.REPORT_BY_ID;
  reportId: string;
  orgId?: string;
};

export function SingleReportPageContainer(
  props: SingleReportPageContainerProps
): ReactElement {
  const router = useRouter();
  const { me } = useMe();
  const organization = me?.organizations.find((org) => org.id === props.orgId);
  const [context] = useQueryState(
    QueryParamNames.PAGE_CONTEXT,
    queryTypes
      .stringEnum<PageType>(Object.values(PageType))
      .withDefault(PageType.BROWSE_ALL)
  );

  const {
    error,
    loading: isLoading,
    data,
  } = useGetReportQuery({
    variables: {
      input: {
        id: props.reportId,
      },
      ...(organization
        ? {
            matchingKeywordsInput: {
              org: {
                id: organization?.id,
                filterBy: ReportsByOrganizationFilter.PRIVATE_KEYWORDS,
              },
            },
          }
        : {}),
    },
  });

  const result = data?.report;

  if (props.orgId && !organization) {
    return (
      <ErrorPageBody
        title="You do not have permission"
        description="You need to be a member of this organization to view its reports"
      />
    );
  }

  const keywords = organization && result?.matchingKeywords;

  const scamReportCardProps =
    result && getScamReportCardPropsFromReport(result);

  /**
   * Handles navigating back to all results. There's no referrer when
   * this page was reached by following a link to it in a non-browser app,
   * like messages. In those cases, we need back button to still go back
   * to context user's expecting based on context query param.
   */
  const handleNavigateBack = () => {
    const { referrer } = document;
    const hasPreviousRoute = !!referrer;

    if (hasPreviousRoute) {
      return router.back();
    }

    const previousRoute = getPreviousRouteFromContextParam(
      router.query,
      context
    );

    router.push(previousRoute);
  };

  return (
    <SingleReportPage
      context={context}
      reportId={props.reportId}
      report={scamReportCardProps}
      isLoading={isLoading}
      error={error ? 'Something went wrong' : undefined}
      showKeywords={props.pageType === PageType.MY_FEED}
      keywords={keywords ?? undefined}
      onBack={handleNavigateBack}
    />
  );
}

export default SingleReportPageContainer;
